import {Injectable} from '@angular/core';
import {HttpBackend, HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {PSocialMedia} from '../../model/param/p-social-media.model';
import {PMobility} from '../../model/param/p-mobility.model';
import {PNeed} from '../../model/param/p-need.model';
import {PPillar} from '../../model/param/p-pillar.model';
import {PAccompanimentCategory} from '../../model/param/p-accompaniment-category.model';
import {PTravel} from '../../model/param/p-travel.model';
import {PProject} from '../../model/param/p-project.model';
import {PMeansTransport} from '../../model/param/p-means-transport.model';
import {PSchoolLevel} from '../../model/param/p-school-level.model';
import {PParcours} from '../../model/param/p-parcours.model';
import {PThematique} from '../../model/param/p-thematique.model';
import {PFinancingMechanism} from '../../model/param/p-financing-mechanism.model';
import {PProfilJeune} from '../../model/param/p-profil-jeune.model';
import {PDomaineMetier} from '../../model/param/p-domaine-metier.model';
import {PDisponibilite} from '../../model/param/p-disponibilite.model';
import {PEntrepriseNeed} from '../../model/param/p-entreprise-need.model';
import {PInterviewPlace} from '../../model/param/p-interview-place.model';
import {PInterviewTheme} from '../../model/param/p-interview-theme.model';
import {PExperienceCategory} from '../../model/param/p-experience-category.model';
import {WorkExperienceMetaDto} from '../../model/dto/metaDto/work-experience-meta.dto';
import {MonitoringMetaDTO} from '../../model/dto/monitoring-meta.dto';
import {PTypeContact} from '../../model/param/p-type-contact.model';
import {PEventTheme} from '../../model/param/p-event-theme.model';
import {InscriptionJobMetaDto} from '../../model/dto/metaDto/inscription-job-meta.dto';
import {PDocument} from '../../model/param/p-document.model';
import {PStatutChangeMotif} from '../../model/param/p-statut-change-motif.model';
import {PStatutSituationComplement} from '../../model/param/p-statut-situation-complement.model';
import {PExperienceEndMotif} from '../../model/param/p-experience-end-motif.model';
import {PContributionContract} from '../../model/param/p-contribution-contract.model';
import {PActivityType} from '../../model/param/p-activity-type.model';
import {ReferentielEnum} from '../../model/param/referentiel.enum';
import {PMonitoringStatus} from '../../model/param/p-monitoring-status.model';
import {PScolarDifficulty} from 'src/app/model/param/p-scolar-difficulty';
import {PDisciplineScolarDifficulty} from 'src/app/model/param/p-discipline-scolar-difficulty';
import {PTypeIntervention} from '../../model/param/p-type-intervention.model';
import {ReferentielAtDate, ReferentielModel} from '../../model/param/referentiel.model';
import {SelectOption} from '../../model/form/select-option.model';
import {PMotif} from '../../model/visit/p-motif.model';
import {PInteretInscriptionAutre} from '../../model/param/p-interet-inscription-autre.model';
import {getMobileToken} from 'src/app/utils/utils.static';
import {PBreakReason} from "../../model/param/p-break-reason.model";

@Injectable({
  providedIn: 'root'
})
export class ParamWebservice {
  private readonly root = `${environment.envApiUrl}/param`;

  private readonly httpMobile: HttpClient;

  constructor(private readonly http: HttpClient,
              private readonly httpBackend: HttpBackend
  ) {
    this.httpMobile = new HttpClient(httpBackend);
  }

  saveParam(referentielEnum: ReferentielEnum, param: ReferentielModel) {
    return this.http.post<ReferentielModel>(`${this.root}/add/${referentielEnum}`, param);
  }

  updateParam(referentielEnum: ReferentielEnum, param: SelectOption) {
    return this.http.put<SelectOption>(`${this.root}/update/${referentielEnum}`, param);
  }

  resetCache() {
    return this.http.get(`${this.root}/resetCache`);
  }

  getAllActivityTypes(onlyActive: boolean = true) {
    return this.http.get<PActivityType[]>(`${this.root}/activity-types?onlyActive=${onlyActive}`);
  }

  getAllPillars(onlyActive: boolean = true) {
    return this.http.get<PPillar[]>(`${this.root}/pillars?onlyActive=${onlyActive}`);
  }

  getAllSocialMedias(onlyActive: boolean = true) {
    return this.http.get<PSocialMedia[]>(`${this.root}/socialMedias?onlyActive=${onlyActive}`);
  }

  getAllMobilities(onlyActive: boolean = true) {
    return this.http.get<PMobility[]>(`${this.root}/mobilities?onlyActive=${onlyActive}`);
  }

  getAllNeeds(onlyActive: boolean = true) {
    return this.http.get<PNeed[]>(`${this.root}/needs?onlyActive=${onlyActive}`);
  }

  getAllAccompaniments(onlyActive: boolean = true) {
    return this.http.get<PAccompanimentCategory[]>(
      `${this.root}/accompaniments?onlyActive=${onlyActive}`
    );
  }

  getAllTravels(onlyActive: boolean = true) {
    return this.http.get<PTravel[]>(`${this.root}/travels?onlyActive=${onlyActive}`);
  }

  getAllProjects(onlyActive: boolean = true) {
    return this.http.get<PProject[]>(`${this.root}/projects?onlyActive=${onlyActive}`);
  }

  getAllMeansTransport(onlyActive: boolean = true) {
    return this.http.get<PMeansTransport[]>(`${this.root}/meansTransport?onlyActive=${onlyActive}`);
  }

  getAllSchoolLevels(onlyActive: boolean = true) {
    return this.http.get<PSchoolLevel[]>(`${this.root}/schoolLevels?onlyActive=${onlyActive}`);
  }

  getAllOrigins(onlyActive: boolean = true) {
    return this.http.get<PSchoolLevel[]>(`${this.root}/origins?onlyActive=${onlyActive}`);
  }

  getAllInterestFields(onlyActive: boolean = true) {
    return this.http.get<PSchoolLevel[]>(`${this.root}/interestFields?onlyActive=${onlyActive}`);
  }

  getAllEntrepriseNeed(onlyActive: boolean = true) {
    return this.http.get<PEntrepriseNeed[]>(
      `${this.root}/entrepriseneeds?onlyActive=${onlyActive}`
    );
  }

  getAllDomaineCompetence(onlyActive: boolean = true) {
    return this.http.get<PDomaineMetier[]>(`${this.root}/domaines?onlyActive=${onlyActive}`);
  }

  getDomaineCompetenceById(id: number) {
    return this.http.get<PDomaineMetier[]>(`${this.root}/domaines/${id}`);
  }

  getAllDisponibilite(onlyActive: boolean = true) {
    return this.http.get<PDisponibilite[]>(`${this.root}/disponibilities?onlyActive=${onlyActive}`);
  }

  getAllProfilJeune(onlyActive: boolean = true) {
    return this.http.get<PProfilJeune[]>(`${this.root}/profils?onlyActive=${onlyActive}`);
  }

  getAllParcours(onlyActive: boolean = true) {
    const token = getMobileToken();
    if (token) {
      const options = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
      return this.httpMobile.get<PParcours[]>(`${this.root}/parcours?onlyActive=${onlyActive}`, options);
    } else {
      return this.http.get<PParcours[]>(`${this.root}/parcours?onlyActive=${onlyActive}`);
    }
  }

  getAllPThematique(onlyActive: boolean = true) {
    return this.http.get<PThematique[]>(`${this.root}/thematique?onlyActive=${onlyActive}`);
  }

  getAllPFinancingMechanism(onlyActive: boolean = true) {
    return this.http.get<PFinancingMechanism[]>(
      `${this.root}/financingMechanism?onlyActive=${onlyActive}`
    );
  }

  getAllActivityTypesFromProgram(idProgram: number) {
    return this.http.get<PParcours[]>(`${this.root}/${idProgram}/activityType`);
  }

  getAllEventThemeFromActivity(idActivityType: number) {
    return this.http.get<PEventTheme[]>(`${this.root}/${idActivityType}/themes`);
  }

  getAllPlaceInterview(onlyActive: boolean = true) {
    return this.http.get<PInterviewPlace[]>(
      `${this.root}/interviewPlaces?onlyActive=${onlyActive}`
    );
  }

  getAllThemeInterview(onlyActive: boolean = true) {
    return this.http.get<PInterviewTheme[]>(
      `${this.root}/interviewThemes?onlyActive=${onlyActive}`
    );
  }

  getAllReasonBreak(onlyActive: boolean = true) {
    return this.http.get<PBreakReason[]>(
      `${this.root}/breakReasons?onlyActive=${onlyActive}`
    );
  }

  getAllExperienceCategory(onlyActive: boolean = true) {
    return this.http.get<PExperienceCategory[]>(
      `${this.root}/experienceCategory?onlyActive=${onlyActive}`
    );
  }

  getAllContractType(onlyActive: boolean = true) {
    return this.http.get<PExperienceCategory[]>(
      `${this.root}/contractType?onlyActive=${onlyActive}`
    );
  }

  getAllFormationType(onlyActive: boolean = true) {
    return this.http.get<PExperienceCategory[]>(
      `${this.root}/formationType?onlyActive=${onlyActive}`
    );
  }

  getAllEnumForWorkExperience(onlyActive: boolean = true) {
    return this.http.get<WorkExperienceMetaDto>(
      `${this.root}/enumWorkExperience?onlyActive=${onlyActive}`
    );
  }

  getAllEnumForMonitoring(onlyActive: boolean = true) {
    return this.http.get<MonitoringMetaDTO>(`${this.root}/enumMonitoring?onlyActive=${onlyActive}`);
  }

  getAllTypeContact(onlyActive: boolean = true) {
    return this.http.get<PTypeContact[]>(
      `${this.root}/typeContactIntervenant?onlyActive=${onlyActive}`
    );
  }

  getAllEventThemeFromActivityTypeList(idActivityType: number[], onlyActive: boolean = true) {
    return this.http.post<PEventTheme[]>(
      `${this.root}/eventThemes?onlyActive=${onlyActive}`,
      idActivityType
    );
  }

  getAllEnumForInscriptionJob() {
    return this.http.get<InscriptionJobMetaDto>(`${this.root}/inscription-job`);
  }

  getAllDocuments(onlyActive: boolean = true) {
    return this.http.get<PDocument[]>(`${this.root}/documents?onlyActive=${onlyActive}`);
  }

  getAllStatutChangeMotif(onlyActive: boolean = true) {
    return this.http.get<PStatutChangeMotif[]>(
      `${this.root}/statutChangeMotif?onlyActive=${onlyActive}`
    );
  }

  getAllStatutSituationComplement(onlyActive: boolean = true) {
    return this.http.get<PStatutSituationComplement[]>(
      `${this.root}/statutSituationComplement?onlyActive=${onlyActive}`
    );
  }

  getAllExperienceEndMotif(onlyActive: boolean = true) {
    return this.http.get<PExperienceEndMotif[]>(
      `${this.root}/experienceEndMotif?onlyActive=${onlyActive}`
    );
  }

  getAllContributionContract(onlyActive: boolean = true) {
    return this.http.get<PContributionContract[]>(
      `${this.root}/contributionContract?onlyActive=${onlyActive}`
    );
  }

  getAllMonitoringStatus(onlyActive: boolean = true) {
    return this.http.get<PMonitoringStatus[]>(
      `${this.root}/monitoringStatus?onlyActive=${onlyActive}`
    );
  }

  getAllScolarDifficulty(onlyActive: boolean = true) {
    const token = getMobileToken();
    if (token) {
      const options = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
      return this.httpMobile.get<PScolarDifficulty[]>(`${this.root}/scolarDifficulty?onlyActive=${onlyActive}`, options);
    } else {
      return this.http.get<PScolarDifficulty[]>(
        `${this.root}/scolarDifficulty?onlyActive=${onlyActive}`
      );
    }
  }

  getAllDisciplineScolarDifficulty(onlyActive: boolean = true) {
    const token = getMobileToken();
    if (token) {
      const options = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
      return this.httpMobile.get<PDisciplineScolarDifficulty[]>(`${this.root}/disciplineScolarDifficulty?onlyActive=${onlyActive}`, options);
    } else {
      return this.http.get<PDisciplineScolarDifficulty[]>(
        `${this.root}/disciplineScolarDifficulty?onlyActive=${onlyActive}`
      );
    }
  }

  getAllInscriptionInterest(onlyActive: boolean = true) {
    return this.http.get<PInteretInscriptionAutre[]>(
      `${this.root}/inscriptionInterest?onlyActive=${onlyActive}`
    );
  }

  getAllVisitReasons(onlyActive: boolean = true) {
    const token = getMobileToken();
    if (token) {
      const options = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
      return this.httpMobile.get<PMotif[]>(`${this.root}/visitReasons?onlyActive=${onlyActive}`, options);
    } else {
      return this.http.get<PMotif[]>(`${this.root}/visitReasons?onlyActive=${onlyActive}`);
    }
  }

  getAllSituationAtDate(onlyActive: boolean = true) {
    return this.http.get<ReferentielAtDate[]>(
      `${this.root}/situationAtDate?onlyActive=${onlyActive}`
    );
  }

  getAllTypeIntervention(onlyActive: boolean = true) {
    return this.http.get<PTypeIntervention[]>(
      `${this.root}/typeIntervention?onlyActive=${onlyActive}`
    );
  }
}

export class SelectOption {
  id: number;
  name: string;
  level?: number;
  selectable?: boolean;
  active?: boolean;
  sorting?: number;
  label?: string;

  listOfOptions: SelectOption[] = [];
}

export class SelectOptionReferentiel extends SelectOption {
  label: string;
}

export class SelectOptionProgram extends SelectOptionReferentiel {
  idProgram: number;
}

export class SelectOptionSituationAtDate extends SelectOptionReferentiel {
  idPSituationAtDate: number;
}

export class SelectOptionActivityType extends SelectOptionReferentiel {
  idPActivityType: number;
}

import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
/**
 * Compare function
 * param a,b
 * 0 => a = b, a null and b -> null
 * 1 => a > b, b null
 * -1 => a < b, a null
 * ========================
 * Between function
 * param a, max, min
 * 0 => between min and max
 * 1 => greater than max
 * -1 => smaller than min
 * undefined => a or max or min -> null
 */
export class CompareService {
  compareNumber(a: number, b: number) {
    const compareUndef = this.compareUndefined(a, b);
    if (compareUndef !== 0) {
      return compareUndef;
    }
    return a > b ? 1 : a < b ? -1 : 0;
  }

  compareDay(a: Date, b: Date) {
    const compareUndef = this.compareUndefined(a, b);
    if (compareUndef !== 0) {
      return compareUndef;
    }

    const aDay = {
      day: a.getDay(),
      month: a.getMonth(),
      year: a.getFullYear()
    };
    const bDay = {
      day: b.getDay(),
      month: b.getMonth(),
      year: b.getFullYear()
    };

    if (aDay.year === bDay.year && aDay.month === bDay.month && aDay.year === bDay.year) {
      return 0;
    }

    return aDay.year > bDay.year || aDay.month > bDay.month || aDay.year > bDay.year ? 1 : -1;
  }

  compareDate(a: Date, b: Date) {
    if (a.getTime() === b.getTime()) {
      return 0;
    }
    return a.getTime() > b.getTime() ? 1 : -1;
  }

  compareDateDesc(a: Date, b: Date) {
    if (!a) {
      return -1;
    }
    if (!b) {
      return 1;
    }
    if (new Date(a).getTime() === new Date(b).getTime()) {
      return 0;
    }
    return new Date(a).getTime() < new Date(b).getTime() ? 1 : -1;
  }

  compareUndefined(a: UndefinedNumberOrDate, b: UndefinedNumberOrDate) {
    if (a === null && b === null) {
      return 0;
    } else if (a === null) {
      return -1;
    } else if (b === null) {
      return 1;
    } else {
      return 0;
    }
  }

  betweenNumber(a: number, min: number, max: number) {
    if (this.testUndefined(a, min, max)) {
      return undefined;
    }

    if (a < min) {
      return -1;
    }
    if (a > max) {
      return 1;
    }
    return 0;
  }

  betweenDay(a: Date, min: Date, max: Date) {
    if (this.testUndefined(a, min, max)) {
      return undefined;
    }

    if (this.compareDay(a, min) < 0) {
      return -1;
    }
    if (this.compareDay(a, max) > 0) {
      return 1;
    }

    return 0;
  }

  betweenDate(a: Date, min: Date, max: Date) {
    if (this.testUndefined(a, min, max)) {
      return undefined;
    }
    if (a.getTime() < min.getTime()) {
      return -1;
    }
    if (a.getTime() > max.getTime()) {
      return 1;
    }
    return 0;
  }

  orderByAsc(a: string, b: string) {
    return a.trim().localeCompare(b.trim());
  }

  testUndefined(a: UndefinedNumberOrDate, min: UndefinedNumberOrDate, max: UndefinedNumberOrDate) {
    return (
      a === null ||
      a === undefined ||
      min === null ||
      min === undefined ||
      max === null ||
      max === undefined
    );
  }
}

type UndefinedNumberOrDate = number | Date | undefined | null;
